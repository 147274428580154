import React, { useEffect, useState } from "react";
import Rating from '@mui/material/Rating';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import APIPath from "../../../config";

import './Packages.css';

const Packages = () => {
    const [packageData, setPackageData] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const cardsPerPage = 3;

    useEffect(() => {
        fetch(`${APIPath}/api/v1/packages/promotions`)
            .then(response => response.json())
            .then(result => {
                const data = result.data.map((val) => val.result);
                setPackageData(data);
                setLoading(false)
            })
            .catch(error => console.log('error', error));
    }, []);

    const navigate = useNavigate();
    const handleCardId = (id) => {
        navigate(`/packages/${id}`);
    }

    const handleNext = () => {
        const lastIndex = packageData.length + 1 ;
        const newIndex = startIndex + cardsPerPage;
        setStartIndex((newIndex <= lastIndex ? newIndex : 1)-1);
    }

    const visibleCards = packageData.length > 0 ? packageData[0].slice(startIndex, startIndex + (cardsPerPage)) : [];

    return (
        <> {loading ? <div className="loader-container">
                <div className="loader"></div>
                </div> :
            <div className="super-sell-container">
                <div className="super-sell-crousel">
                    <h2>Super Sell 2023-24</h2>
                    <p>
                        <button onClick={handleNext} className="supersell-btn"> <IoIosArrowBack /> </button>
                        <button onClick={handleNext} className="supersell-btn"> <IoIosArrowForward /> </button>
                    </p>
                </div>

                <div className="super-sell">
                    {visibleCards.map((item, id) => (
                        <div className="super-sell-card" key={id}  onClick={() => handleCardId(item._id,item.title)}>
                            <div className="super-sell-card-image">
                                <img src={item.thumbnailImage} alt="Card" />
                            </div>

                            <div className="super-sell-card-details">
                                <h3 onCopy={(e)=>e.preventDefault()}>{item.title}</h3>
                                <p>{item.duration}</p>
                                <div className="super-sell-rating-icon">
                                    <p>Ratings &nbsp;</p>
                                    <p><Rating defaultValue={item.ratings} precision={0.5} readOnly /></p>
                                </div>
                                <div className="supercell-price-arrow">
                                    <p>
                                        Price per person: <b>AED {item.price[0].price}</b>
                                    </p>
                                    <p>
                                        <ArrowOutwardIcon />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        }
        </>
    );
}

export default Packages;
