import react,{useState} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { HiOutlineIdentification } from "react-icons/hi2";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import './Videocontainer.css';
import Packages from '../Pages/Packages/Packages';
import TopPackages from '../Pages/TopPackages/TopPackages';
import PackageByCategory from '../Pages/PackageByCategory/PackageByCategory';
import ShopingStops from '../Pages/ShopingStops/ShopingStops';
import ThingsIndubai from '../Pages/ThingsInDubai/ThingsIndubai';
import Footer from '../Footer/Footer';
import Delicacies from '../Pages/Delicacies/Delicacies';
import Feedback from '../Pages/Feedback/Feedback';

const VideoContainer=()=> {
    const [initialstate,setinitialState]=useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [showPackages, setShowPackages] = useState(false);
    const [category,setCategory]=useState(false);
    const [stops,setStops]=useState(false);
    const [attraction,setAttraction]=useState(false);
    const [feedback,setFeedback]=useState(false);

    const handleSearch = () => {
      setinitialState(false)
        // ------------------------------------------ Packages Search ----------------------------
        const searchTermLower = searchTerm.toLowerCase();
        console.log('Performing search:', searchTermLower);
        if(searchTermLower === ''){
          setinitialState(true)
        }
        const isPackageSearch=searchTermLower.includes('packages') || 
        searchTermLower.includes('package') || 
        searchTermLower.includes('pack') ||
        searchTermLower.includes('dubai');
        setShowPackages(isPackageSearch);
       //-------------------------------------------Category Search ----------------------------------
        const isCategory =
        searchTermLower.includes('category') ||
        searchTermLower.includes('cat') ||
        searchTermLower.includes('categories');
        setCategory(isCategory)
       //------------------------------------------ Shoping-Stops search ------------------------------
       const isStops=
       searchTermLower.includes('stops') ||
       searchTermLower.includes('stops in dubai') ||
       searchTermLower.includes('shoping') ||
       searchTermLower.includes('shopping') ;
       setStops(isStops);
       //-----------------------------------------Attraction search -------------------------------------
       const isAttraction=
       searchTermLower.includes('attraction') ||
       searchTermLower.includes('attractions') ||
       searchTermLower.includes('attrcations in dubai');
       setAttraction(isAttraction);
      //  -------------------------------------------- Feedback Search ----------------------------------
      const isFeedback=
      searchTermLower.includes('feedback') ||
      searchTermLower.includes('feed');
      setFeedback(isFeedback);
    };

    return <>
       <div className="banner">
        <video autoPlay muted loop className="myvideo">
            <source src="https://res.cloudinary.com/ddxawuqwy/video/upload/v1705380585/ocvjdle2ubiq9f8pxif9.mp4" type="video/mp4" />
        </video>
        <div className="main-container">
            <h2 className="banner-title"> Dubai </h2>
            <p class="banner-text1">Your Adventure Awaits in the Desert Oasis</p>
            
            <div id="search-container">
            <form className="video-form" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
                  <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    
                  />
                    <button  className="search-button"><SearchIcon/> </button>
                </form>
            </div>
            <p className="search-packages-combos-attractions1">
                <a href='/package' className="check-button">View Packages &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                <a href='/package' className="check-button-for-mobile">Packages
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href='/landcombos' className="check-button">Land Combos &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                <a href='/package' className="check-button-for-mobile">Combos
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href='/attractions' className="check-button">Attractions &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                <a href='/attractions' className="check-button-for-mobile">Attractions
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
            </p>
        </div>
       </div>
    <div className="banner-bar">
        <div className="banner-details">
          <div className='video-footer'> 
           <p><FaRegClock fontSize="1.2rem" />&nbsp;</p>
            <p className='video-footer-text'>Minimum 3 Nights</p>   
          </div>    
        </div>
        <div className="banner-details">
        <div className='video-footer'> 
           <p><FaRegCalendarAlt fontSize="1.2rem"/>&nbsp;</p>
            <p className='video-footer-text'>Peak Time Nov to Mar</p>   
          </div>
        </div>
        <div className="banner-details">
             <p>AED 1530 Per Person</p>
        </div>
        <div className="banner-details">
           <p> <HiOutlineIdentification fontSize="1.5rem" /> 7 Days Visa processing</p>
        </div>
    </div>
    {/* -----------------------------------Initial State---------------------- */}
    {initialstate && (
      <div>
        <div className='unveil'>
        <h2>Unveil the Oasis of Luxury</h2>
        <p>Welcome to Dubai, a city where the desert's timeless beauty seamlessly blends with modern 
            luxury, creating an inviting haven for travelers from around the world. As you arrive, 
            the striking skyline, adorned with towering marvels of architecture, welcomes you to a 
            place where dreams are transformed into reality. Explore the bustling souks, where the 
            air is filled with the scents of spices and the vibrant colors of textiles beckon you to 
            indulge in retail therapy like no other. Alongside the traditional, savor exotic flavors 
            from a global culinary palette that has made Dubai a gastronomic paradise.</p>
       </div>
      <br/> 
        <Packages/>
        <PackageByCategory/>
        <TopPackages/>
        <ShopingStops/>
        <Delicacies/>
        <ThingsIndubai/>
        <Feedback/>
        {/* <Footer/> */}
      </div>
    )}
    {/* ---------------------------------- */}
    {showPackages && (
        <div className="packages-card">
          <Packages />
          <TopPackages/>
          <PackageByCategory/>
        </div>
      )}
      {category && (
        <div className='category-card' style={{width:"100%"}}>
            <PackageByCategory />
        </div>
      )}
      {stops && (
        <div>
            <ShopingStops/>
        </div>
      )}
      {attraction && (
        <div style={{marginTop:"1rem"}}>
            <ThingsIndubai/>
        </div>
      )}
      {feedback && (
        <div>
          <Feedback/>
        </div>
      )}
    <br/>
    <br/>
    <Footer/>
    </>
}
export default VideoContainer;