import { useEffect } from 'react';
import { BrowserRouter as Router,Route,Routes, useLocation } from 'react-router-dom';
import PackageDetails from './Component/Pages/PackageDetails/PackagesDetails';
import Home from './Component/Home';
import './App.css';

import Navbar from './Component/Navbar/Navbar';
import ContactUs from './Component/ContactUs/ContactUs';
import Aboutus from './Component/AboutUs/Aboutus'
import TopPackages from './Component/Pages/TopPackages/TopPackages';
import PackageNav from './Component/Pages/PackageNav/PackageNav';
import CategoryDetails from './Component/Pages/CategoryDetails/CategoryDetails';
import ShopingStopsDetails from './Component/Pages/ShopingStopsDetails/ShopingStopsDetails';
import DelicaciesDetails from './Component/Pages/DelicaciesDetails/DelicaciesDetails';
import Attraction from './Component/Pages/Attractions/Attractions';
import AttractionDetails from './Component/Pages/AttractionDetails/AttractionDetails';
import LandCombos from './Component/Pages/LandCombos/LandCombos';
import LandCombosDetails from './Component/Pages/LandCombosDetails/LandCombosDetails';
import Privacy from './Component/Privacy';
import Condition from './Component/Condition';
import LogIn from './Component/LogIn/LogIn';
import AttractionByCategory from './Component/Pages/AttractionByCategory/AttractionByCategory';



function App() {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">
        <Navbar />
        {/* <Router> */}
         <Routes>
            <Route path="/" element={<Home/>} />
            <Route path='/packages' element={<PackageNav/>}/>
            <Route path='/attractions' element={<Attraction />}/>
            <Route path='/attractions/:id' element={<AttractionByCategory/>}/>
            <Route path='/landcombos' element={<LandCombos/>}/>
            <Route path='/contactus' element={<ContactUs/>}/>
            <Route path='/login' element={<LogIn />}/>
            <Route path='/aboutus' element={<Aboutus/>} />
            <Route path='/privacy' element={<Privacy/>}/>
            <Route path='/condition' element={<Condition/>} />
            <Route path='/toppackage' element={<TopPackages/>}/>
            <Route path="/packages/:id" element={<PackageDetails/>} />
            <Route path='/attraction/:id' element={<AttractionDetails/>}/>
            <Route path='/landcombos/:id' element={<LandCombosDetails/>}/>
            <Route path='/packages/:id' element={<CategoryDetails/>}/>
            <Route path='/packagebycategory/:categoryName' element={<CategoryDetails />} />
            <Route path='/shopingdetails/:id' element={<ShopingStopsDetails/>}/>
            <Route path="/delicaciesdetails/:id" element={<DelicaciesDetails />} />
            
         </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
