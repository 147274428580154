import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './DelicaciesDetails.css';
import Footer from "../../Footer/Footer";
import APIPath from "../../../config";

const DelicaciesDetails=()=>{
    const { id } = useParams();

    const [delicaciesDetails,setDelicaciesDetails]=useState(null)
    useEffect(()=>{
         fetch(`${APIPath}/api/v1/delicacies/?id=${id}`)
         .then((res)=> res.json())
         .then((data)=>{
            // setDelicaciesDetails(data.data)
            const timeoutId = setTimeout(() => {
              setDelicaciesDetails(data.data);
            }, 500); 
        
            return () => clearTimeout(timeoutId);
            
         })
         .catch((err)=>{
            console.log(err)
         })
    },[])

    if (!delicaciesDetails) {
      return (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      );
    }

    return <>
        <div className="delicacies-container">
          {delicaciesDetails.map((val,id)=>{
            return <>
                 <div className="delicacies-banner" key={id} >
                    <img src={val.bannerImage} />
                 </div>
                 <div className="delicacies-details">
                      <h3>{val.title}</h3>
                      <div className="delicacies-details-description">
                        {val.description.map((item,id)=>{
                            return <>
                              <p key={id}>{item}</p>
                            </>
                        })}
                      </div>
                      <div className="delicacies-opening-hours">
                        <h4>Hours</h4>
                        {val.openingHours.map((item,id)=>{
                            return <>
                             <p key={id}>{item}</p>
                            </>
                        })}
                      </div>
                      <br/>
                      <div className="delicacies-address">
                         <p><b>Address: </b> {val.address}</p>
                      </div>
                      <br/>
                      <div className="delicacies-inquiry-mobileNumber">
                        <p><b>Inquiries: </b> {val.inquiryNumber}</p>
                      </div>
                      <br/>
                      <div className="delicacies-email">
                        <p><b>Email: </b> {val.email}</p>
                      </div>
                      <br />
                      <div>
                        <p><b>Visit Website: </b><a href={val.website} target="blank">{val.website}</a></p>
                      </div>
                 </div>
                 <Footer/>
              </>
          })}
        </div>
    </>
}
export default DelicaciesDetails;