import React, { useEffect, useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import './ShopingStops.css';
import APIPath from "../../../config";

const ShopingStops = () => {
  const [shopingstops, setShopingstops] = useState([]);
  const [shopingstops2, setShopingstops2] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${APIPath}/api/v1/stops`)
      .then((res) => res.json())
      .then((data) => {
        const firstThreeCards = data.data.slice(0, 3);
        const nextFourCards = data.data.slice(3, data.data.length);
        setShopingstops(firstThreeCards);
        setShopingstops2(nextFourCards);
        setLoading(false)
      });
  }, []);

  const navigate = useNavigate();

  const handleCardId = (id) => {
    navigate(`/shopingdetails/${id}`);
  };


  const handleNext2 = () => {
    const newShopingstops2 = [...shopingstops2];
    const lastCard2 = newShopingstops2.pop();
    newShopingstops2.unshift(lastCard2);
    setShopingstops2(newShopingstops2);
  };
  const handleNext = () => {
    const newShopingstops = [...shopingstops];
    const lastCard = newShopingstops.pop();
    newShopingstops.unshift(lastCard);
    setShopingstops(newShopingstops);
  };

  return (
    <>
    {loading ? <div className="loader-container">
                <div className="loader"></div>
                </div> :
    <div>
      <div className="shopingstops-conatiner">
        <div className="shopingstops-top1">
          <h4>Shopping stops for you in Dubai</h4>
          <p>
            <button onClick={handleNext} className="supersell-btn">
              <IoIosArrowBack />
            </button>
            <button onClick={handleNext} className="supersell-btn">
              <IoIosArrowForward />
            </button>
          </p>
        </div>

        <div className="shopingstops-card-parent">
          {shopingstops.map((val, id) => (
            <div className="shopingstops-card" key={id} onClick={() => handleCardId(val._id)}>
              <div className="shopingstops-card-image">
                <img src={val.bannerImage} alt="logo" />
                 <p><IoLocationOutline /> {val.location}</p>
              </div>
              <div className="shopingstops-logo">
                <img src={val.logoImage}
                  alt="Logo" />
              </div>
            </div>
          ))}
        </div>
      </div>
       <div className="shopingstops-top">
          <h4>What else to do in Dubai</h4>
          <p>
            <button onClick={handleNext2} className="supersell-btn">
              <IoIosArrowBack />
            </button>
            <button onClick={handleNext2} className="supersell-btn">
              <IoIosArrowForward />
            </button>
          </p>
        </div>
        <div className="shoping-stops-card4-parent">
          {shopingstops2.map((val, id) => (
            <div
              className="shoping-stops-card4"
              key={id}
              onClick={() => handleCardId(val._id)}
            >
              <div className="shoping-stops-card4-image">
                <img src={val.bannerImage} alt="Logo" />
                <p>
                  <IoLocationOutline /> {val.location}
                </p>
              </div>
              <div className="shoping-stops-card4-details">
                <h5>{val.title}</h5>
              </div>
            </div>
          ))}
        </div>
    </div>  
   }  
    </>
  );
};

export default ShopingStops;

