import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import './ShopingStopsDetails.css';
import Footer from "../../Footer/Footer";
import APIPath from "../../../config";

const ShopingStopsDetails = () => {
  const { id } = useParams();
  const [selectedStop, setSelectedStop] = useState(null);

  useEffect(() => {
    fetch(`${APIPath}/api/v1/stops/?id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        // setSelectedStop(data.data);
        const timeoutId = setTimeout(() => {
          setSelectedStop(data.data);
        }, 500); 
        return () => clearTimeout(timeoutId);
      });
  }, [id]);

  if (!selectedStop) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <div className="shopingstops-container">
        {selectedStop.map((val, id) => (
          <div key={id}>
            <div className="shopingstop-image">
              <img src={val.bannerImage} alt="Thumbnail" />
              {/* <img src="https://magicalvacation.com//upload/stops/banner_20231019064702291.jpg" alt="" /> */}
            </div>
            <div className="shopingstops-description">
                <br/>
                <h2 style={{fontWeight:"600"}}>{val.title}</h2>
                <p className="shoping-location"><IoLocationOutline/> {val.location}</p>
                {/* <p className="shoping-description">{val.description}</p> */}
                {val.description.map((val,id)=>{
                  return<>
                    <p key={id} style={{marginBottom:"1rem"}}>{val}</p>
                  </>
                })}
                <br/> <br/>
                {/* <p className="shoping-overview">{val.overview}</p> */}
            </div>
        </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default ShopingStopsDetails;

