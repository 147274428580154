import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PopupForm from "../PackageDetails/PopupForm/PopupForm";
import { IoEyeOffSharp } from "react-icons/io5";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { IoEye } from "react-icons/io5";
import './AttractionDetails.css';
import APIPath from "../../../config";

const AttractionDetails = () => {
  const { id } = useParams()
  const [attractionDetails, setAttractionDetails] = useState([])
  const [showForm, setShowForm] = useState(false);
  const [blur, setBlure] = useState(false);
  const [showMorePrice, setShowMorePrice] = useState(false);
  const [priceIcon, setPriceIcon] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleQueryButtonClick = () => {
    setShowForm(true);
    setBlure(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setBlure(false);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    setLoading(false);
    fetch(`${APIPath}/api/v1/attractions/?id=${id}`)
      .then(res => res.json())
      .then((data) => {
        if (data.data && data.data.length > 0) {
          setAttractionDetails(data.data[0]);
        }
      })
      .catch((err) => {
        alert(err)
      })
  }, [id]);

  const AttractionPriceTable = () => {
    if (!attractionDetails) {
      return <div>
        Loading....
      </div>
    }
    return (
      <table style={{textAlign:"center"}}>
        <thead style={{background:"#B8E2E3"}}>
          <tr>
            <th className="att-price-table">Description</th>
            <th className="att-price-table">Adult Price</th>
            <th className="att-price-table">Child Price</th>
          </tr>
        </thead>
        <tbody>
          {attractionDetails?.price?.map((val, id) => (
            <tr key={id} style={{background:"#fff",borderBottom:"1.5px solid #26DB26"}}>
              <td style={{color:"#00081d",borderRight:"1.5px solid #26DB26",padding:" 5px 0.5rem",maxWidth:"80%"}}>{val.description}</td>
              <td style={{color:"#00081d",borderRight:"1.5px solid #26DB26",padding:" 5px 0.5rem"}}>AED <b>{val.adultPrice}</b></td>
              <td style={{color:"#00081d",padding:" 5px 0.5rem"}}>AED <b>{val.childPrice}</b></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const [isScrolled, setIsScrolled] = useState(true);
  const PackageName = attractionDetails.title;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY < 200);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const Price = attractionDetails?.price?.[0]?.adultPrice;
  // console.log(attractionDetails?.cost?.[0]?.cost1.split(" ")[1])

  return <>
     {loading ? <div className="loader-container">
        <div className="loader"></div>
      </div> :
      <div className={blur ? "packege-details" : "package"}>
      <div className="attraction-details-page-image">
        <img src={attractionDetails.bannerImage} alt={attractionDetails.name} />
      </div>
      <div className="attraction-details-page">
        <h3>{attractionDetails.title}</h3>
        <p>{attractionDetails.attractionOverview}</p>
        <br />
        <div className="inclusion">
          <h3>Inclusion: </h3>
          {attractionDetails.includedServices?.length ? (
                attractionDetails.includedServices.map((val, id) => (
                  <ul key={id}>
                    <li>{val}</li>
                  </ul>
                ))
              ) : (
                <h5>N/A</h5>
              )}
          {/* {attractionDetails.includedServices?.map((val, id) => {
            return <>
              <ul key={id}>
                <li>{val}</li>
              </ul>
            </>
          })} */}
        </div>
        <br />
{/* ------------------------------------ Exclusion --------------------------------------------         */}
        <div className="exclusion">
          <h3>Exclusion: </h3>
          {attractionDetails.excludedServices?.length ? (
                attractionDetails.excludedServices.map((val, id) => (
                  <ul key={id}>
                    <li>{val}</li>
                  </ul>
                ))
              ) : (
                <h5>N/A</h5>
              )}
        </div>
        <br />
        <div className="expectation">
          <h3>Expectation:</h3>
              {attractionDetails?.expectations?.length > 0 ? (
              attractionDetails.expectations.map((val, id) => (
                <div className="expectation-details" key={id}>
                  <h4><b>Location :</b> {val.location}</h4>
                  <h4><b>Description :</b> {val.description}</h4>
                  <h4><b>Time :</b> {val.time}</h4>
                </div>
              ))
            ) : (
              <div className="expectation-details">
                <h4><b>N/A</b></h4>
              </div>
            )}

        </div>

      </div>
      <div className={`footer ${isScrolled ? 'footer-hidden' : ''}`}>
        <div style={{width:"50%"}}>
          <p>
            Starting at: <span><b> AED {Price}</b> per person</span>
            <button
              id="more-price-btn"
              onClick={() => {
                setShowMorePrice(!showMorePrice);
                setPriceIcon(!priceIcon);
              }}
            >
              {priceIcon ? < IoEyeOffSharp /> : <IoEye />}
            </button>
            <div className={showMorePrice ? 'morePrice' :'morePriceNone'}>
              <AttractionPriceTable />
            </div>

          </p>
        </div>
        <div>
          <div className="attraction-footer-price">
                <p className="footer-more-desktop"> To plan your next tour with US!! click on &nbsp;</p>
                <p className="footer-more-tab-mob"> For More! click here &nbsp;</p>
                <button onClick={handleQueryButtonClick}>Send a query < ArrowOutwardIcon fontSize="1.5rem"/></button>
    
          </div>
        </div>
      </div>
      {showForm && <PopupForm onClose={handleCloseForm} packageId={id} packageName={PackageName} />}
    </div>
}
  </>
}

export default AttractionDetails;
