import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import APIPath from "../../../config";
import { useNavigate } from "react-router-dom";
import './Landcombos.css';
import Footer from "../../Footer/Footer";

const LandCombos=()=>{
    const [landCombos,setLandCombos]=useState([]);
    const [originallandcombos, setOriginallandcombos] = useState([]);
    const [searchTerm,setSearchTerm]=useState('');
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
       fetch(`${APIPath}/api/v1/land_combos`)
       .then((res)=>res.json())
       .then((data)=>{
        setLandCombos(data.data);
        setOriginallandcombos(data.data);
        setLoading(false)
       })
       .catch((err)=>{
        alert(err)
       })
    },[])

    const navigate = useNavigate();
    const handleCardId = (id) => {
        navigate(`/landcombos/${id}`);
    }
    // ------------------------------------ Search functions----------------------
    const handleSearch=()=>{
        const searchTermLower=searchTerm.toLowerCase();
        console.log("serach Key: ",searchTermLower);
  
        if (searchTermLower === "") {
            setLandCombos(originallandcombos);
        }
        else {
          const filteredLandCombos = originallandcombos.filter((combos) =>
            combos.title.toLowerCase().includes(searchTermLower)
          );
          setLandCombos(filteredLandCombos);
        }
      }

    return <>
         <div className='package-banner'>
         <div className="main-container">
             <br/>
            <h2 style={{marginBottom:"1rem"}}> Land Combos for you! </h2>
            <div id="search-container">
                <form className='video-form' onSubmit={(e)=>{e.preventDefault(); handleSearch()}}>
                    {/* <input className="search-input" type="text" placeholder="Search..." required/> */}
                    <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                    <button  className="search-button"> <SearchIcon/> </button>
                </form>
            </div>
            <p className="search-packages-combos-attractions">
                <a href="/package" className="check-button">View Packages &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="landcombos" className="check-button">Land Combos &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="attractions" className="check-button">Attractions &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
            </p>
        </div>
      </div>
      <div className="landcombos">
             <h2> Trending </h2>
             {loading ? <div className="loader-container">
                <div className="loader"></div>
                </div> :
             <div className="landcombos-card-parent">
                {landCombos.map((val,id)=>{
                    return <>
                        <div className="landcombos-card" onClick={() => handleCardId(val._id)}>
                            <div className="landcombos-card-image">
                                <img src={val.thumbnailImage} alt={val.title} />
                            </div>
                            <div className="landcombos-card-title">
                                <h4>{val.title}</h4>
                            </div>
                        </div>
                    </>
                })}
             </div>
            }
      </div>
      <Footer/>
    </>
}
export default LandCombos;