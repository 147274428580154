import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './PackageByCategory.css';
import APIPath from "../../../config";

const PackageByCategory = () => {
    const [categories, setCategories] = useState([]);
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        fetch(`${APIPath}/api/v1/categories`)
            .then(response => response.json())
            .then(result => { 
                setCategories(result.data);
            })
            .catch(error => console.log('error', error));
    }, []); 

    const navigate = useNavigate();

    const handleCardId = (id, name) => {
        navigate(`/packagebycategory/${encodeURIComponent(name)}`, { state: { id } });
    }

    const topSixPackages = categories.slice(startIndex, startIndex + 6);

    return (
        <> 
            <div className="category-container">
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <h3 style={{margin:"0"}}>Choose what suits you the best</h3>
                    {/* <p>
                        <button onClick={handlePrev} className="supersell-btn"><GrPrevious/></button>
                        <button onClick={handleNext} className="supersell-btn"><GrNext/></button>
                    </p> */}
                </div>
                <div className="category-card-container">
                    {topSixPackages.map((val, id) => {
                        return (
                            <div key={id} className="category-card-parent" onClick={() => handleCardId(val._id,val.name)}>
                                <div className="category-card-image">
                                    <img src={val.categoryImageUrl} alt="CategoryImage"/>
                                </div>
                                <div className="category-card-details">
                                    <h4 style={{ color: val.colourCode }}>{val.name}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default PackageByCategory;

