import React, { useEffect, useState } from "react";
import './ThingsIndubai.css';
import { useNavigate } from "react-router-dom";
import APIPath from "../../../config";

const ThingsIndubai = () => {
    const type = 'attraction';
    const [thingsToDo, setThingsToDo] = useState([]);

    useEffect(() => {
        fetch(`${APIPath}/api/v1/categories`)
            .then((res) => res.json())
            .then((data) => {
                const filteredData = data.data.filter(item => item.type === type);
                setThingsToDo(filteredData);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const navigate=useNavigate();
    const CardClick =(id)=>{
        // console.log(id)
        navigate(`/attractions/${id}`)
    }
    return (
        <>
            <div className="InDubai">
                <h2>Things To Do In Dubai </h2>
            </div>
            <div className="attraction-grid-parent">
                <div style={{width:"100%", margin:"0",padding:"0"}}>
                  <div className="attraction-grid-type1">
                        <div className="attraction-item" onClick={()=>CardClick(thingsToDo[0]?._id)}>
                            <img src={thingsToDo[0]?.categoryImageUrl}  />
                        </div>
                        <br/>
                        <div className="attraction-item" onClick={()=>CardClick(thingsToDo[2]?._id)}>
                            <img src={thingsToDo[2]?.categoryImageUrl} />
                        </div>
                  </div>
            
                </div>
                <div className="mobile-view">
                <div style={{width:"60%",marginRight:"1rem"}}>
                    <div className="attraction-item2" onClick={()=> CardClick(thingsToDo[1]?._id)}>
                        <img src={thingsToDo[1]?.categoryImageUrl}  />
                        
                    </div>
                    
                </div>
                <div style={{width:"60%",marginRight:"1rem"}}>
                
                        <div className="attraction-item" onClick={()=> CardClick(thingsToDo[3]?._id)}>
                            <img src={thingsToDo[3]?.categoryImageUrl} alt={thingsToDo[3]?.name} />
                        </div>
                        <br/>
                        <div className="attraction-item" onClick={()=> CardClick(thingsToDo[4]?._id)}>
                            <img src={thingsToDo[4]?.categoryImageUrl} alt={thingsToDo[4]?.name} />
                            
                        </div>
                </div>
                </div>
                
                
                <div style={{width:"100%", margin:"0",padding:"0"}}>
                       <div className="attraction-item2" onClick={()=> CardClick(thingsToDo[5]?._id)}>
                            <img src={thingsToDo[5]?.categoryImageUrl} alt={thingsToDo[5]?.name} />
                            
                        </div>
                </div>
            </div>
    
        </>
    );
};

export default ThingsIndubai;
