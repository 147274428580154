
import React, { useState, useEffect } from "react";
import './TopPackages.css'
import Rating from '@mui/material/Rating';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import APIPath from "../../../config";

const TopPackages = () => {
    const [topPackage, setTopPackage] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${APIPath}/api/v1/packages`)
            .then(response => response.json())
            .then(result => {
                setTopPackage(result.data);
                setLoading(false)
            })
            .catch(error => console.log('error', error));
    }, []);

    const topFivePackages = topPackage.slice(startIndex, startIndex + 4);
    
    const navigate = useNavigate();
    const handleCardId = (id) => {
        navigate(`/packages/${id}`);
    }

    const handleNext = () => {
        if (startIndex + 4 < topPackage.length) {
            setStartIndex(prevIndex => prevIndex + 1); 
        } else {
            setStartIndex(0); 
        }
    };
    
    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(prevIndex => prevIndex - 1); 
        } else {
            const lastIndex = topPackage.length - 4;
            setStartIndex(lastIndex > 0 ? lastIndex : 0); 
        }
    };
    
    return (
        <>  {loading ? <div className="loader-container">
                <div className="loader"></div>
                </div> :
            <div className="top-package-container">
                <div className="top-package-crousel">
                    <h2>Top Packages for Dubai</h2>
                    <p>
                         <button onClick={handlePrev} className="supersell-btn"> <IoIosArrowBack /> </button>
                         <button onClick={handleNext} className="supersell-btn"><IoIosArrowForward /></button>
                     </p>
                </div>
                
                 <div className="top-package-card-container">
                      {topFivePackages.map((val, id) => (
                         <div key={id} className="top-package-card" onClick={() => handleCardId(val._id)}>
                             <div className="top-package-card-image">
                               <img src={val.images} alt={val.title} />
                             </div>
                            
                            <div className="top-package-card-details" >
                                <br/>
                                 <h4>{val.title}</h4>
                                 <div className="rating-and-ratingicon">
                                    <div className="ratings">
                                        <p>Ratings &nbsp; </p>
                                    </div>
                                    <div>
                                        <p >
                                        <Rating style={{fontSize:"1.2rem",overflow:"hidden"}} defaultValue={val.ratings} precision={0.5}  readOnly  />
                                        </p>
                                    </div>

                                  </div>
                                 <p>
                                     <AccessTimeIcon style={{fontSize:"1rem"}}/><span style={{fontSize:"0.9rem"}}> 3 Hrs</span> &nbsp;
                                     <DirectionsCarIcon style={{fontSize:"1rem"}}/> <span style={{fontSize:"0.9rem"}}>Pickup offered </span> &nbsp;  
                                     <LanguageIcon style={{fontSize:"1rem"}}/><span style={{fontSize:"0.9rem"}}> Eng</span> &nbsp;
                                 </p>
                                 <div className="price-arrow">
                                    <p>Price per person:
                                      <b> AED &nbsp;<strong>{val.price[0].price}</strong> </b>
                                    </p>
                                     <p><ArrowOutwardIcon /></p>
                                 </div>
                                 
                             </div>
                           
                         </div>
                     ))} 
                 </div>
             </div> 
        }   
         </>
     );
 };

export default TopPackages;

