import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';

const CustomNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="black" className='navbar'>
      <Container fluid className='navbar-container'>
        <Navbar.Brand href="/">
          <img src='https://res.cloudinary.com/ddxawuqwy/image/upload/v1708420873/packages/mvlogo_mc4ai4.png' className='logo-image'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto"> 
            <Nav.Link as={NavLink} to="/" className={`list ${location.pathname === '/' ? 'active' : ''}`}>Home</Nav.Link>
            <Nav.Link as={NavLink} to="/packages" 
            className={`list ${
              location.pathname.startsWith('/packages/') ||
              location.pathname.startsWith('/packagebycategory/')
                ? 'active'
                : ''
            }`}
            >Packages</Nav.Link>
            <Nav.Link as={NavLink} to="/attractions" 
            className={`list ${location.pathname.startsWith('/attraction/') ? 'active' : ''}`}
            >Attractions</Nav.Link>
            <Nav.Link as={NavLink} to="/landcombos" className={`list ${location.pathname === '/landcombos' ? 'active' : ''}`}>Land&nbsp;Combos</Nav.Link>
            <Nav.Link as={NavLink} to="/contactus" className={`list ${location.pathname === '/contactus' ? 'active' : ''}`}>Contact us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default CustomNavbar;

