import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { IoMdArrowForward } from "react-icons/io";
import { RiDoubleQuotesL } from "react-icons/ri";
import { FaQuoteLeft } from "react-icons/fa";
import './Feedback.css';
import APIPath from "../../../config";

const Feedback = () => {
    const [feedback, setFeedback] = useState([]);
    const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);

    useEffect(() => {
        fetch(`${APIPath}/api/v1/reviews`)
            .then((res) => res.json())
            .then((data) => {
                // console.log(data.data);
                setFeedback(data.data);
            });
    }, []);

    const feedbackLoop = () => {
        if(currentFeedbackIndex < (feedback.length -1)){
            setCurrentFeedbackIndex((prevIndex) => (prevIndex + 1));
        }
        else {
            setCurrentFeedbackIndex(0)
        }
        
    };

    return <>
        <div className="feedback-container-main">
            <div className="prefeedback">
            <IoMdArrowBack onClick={feedbackLoop}/>
            </div>
            <div className="feedback">
                    <div className="feedback-container">
                        <h3>What people say about us</h3>
                        <div className="feedback-container-parent">
                            {feedback.length > 0 && (
                                <div className="feedback-container-details">
                                    <div className="feedback-container-image">
                                        <img src={feedback[currentFeedbackIndex].reviewerImage} alt={feedback[currentFeedbackIndex].reviewerName} />
                                    </div>
                                    <div className="feedback-container-reviewerName">
                                        <h3>{feedback[currentFeedbackIndex].reviewerName}</h3>
                                    </div>
                                    <div className="feedback-container-review">
                                        <p><FaQuoteLeft style={{marginBottom:"0.8rem"}} /> {feedback[currentFeedbackIndex].comment}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
            </div>
            <div className="nextfeedback" >
            <IoMdArrowForward  onClick={feedbackLoop} />
            </div>
        </div>
         
        
    </>
}

export default Feedback;
