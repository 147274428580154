import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './LogIn.css';
import { useNavigate } from "react-router-dom";

const LogIn = () => {
    const [showSignUpFields, setShowSignUpFields] = useState(false);
    const [showSignInFields, setShowSignInFields] = useState(true);
    const [userEmail,setuserEmail]=useState('');
    const [userpassword,setuserPassword]=useState('');

    // const handleChange =(e)=>{
    //     setuserEmail(e.target.value);
    //     setuserPassword(e.target.value);
    // }
    const navigate=useNavigate()
    const LogInUser =()=>{
        console.log("User Emial: ",userEmail,"User Password: ",userpassword);
        if(userEmail==="dev@gmail.com" && userpassword ==="1234"){
            navigate('/')
            return true;
        }
        else {
            alert("userEmail and Password is not matching ")
            return false
        }
    }

    const handleSignUpClick = () => {
        setShowSignUpFields(true);
        setShowSignInFields(false);
    };

    const handleSignInClick = () => {
        setShowSignUpFields(false);
        setShowSignInFields(true);
    };

    return (
        <>
            <div className="login-container">
                <div className="login-image">
                    <img src="/river-972708_640.jpg" alt="River Bank" />
                </div>
                <div className="login">
                    <h4>Welcome To Magical Vacation</h4>
                    <form className="login-form" onSubmit={(e)=>{e.preventDefault(); LogInUser();}}>
                        <div className="signin-signup-btn">
                            <button className={`signin-btn ${showSignInFields ? 'active' : ''}`} onClick={handleSignInClick}>Sign In</button>
                            <button className={`click-btn ${showSignUpFields ? 'active' : ''}`} onClick={handleSignUpClick}>Sign Up</button>
                        </div>

                        {showSignInFields && (
                            <>
                                <label className="login-label">Email or UserId</label>
                                <input className="login-input-field" type="text" placeholder="Enter your Email..."
                                value={userEmail}
                                 onChange={(e)=>{
                                    setuserEmail(e.target.value)
                                 }}
                                required />
                                <label className="login-label">Password</label>
                                <input className="login-input-field" type="password" placeholder="Enter your password..."
                                value={userpassword} 
                                 onChange={(e)=>{
                                    setuserPassword(e.target.value)
                                 }}
                                required/>
                                <div className="forget-password">
                                       <a href="#"><p>Forget Password?</p></a>
                                </div>
                            </>
                        )}

                        {showSignUpFields && (
                            <>
                                <label className="login-label">Name</label>
                                <input className="login-input-field" type="text" placeholder="Enter your name..." required/>
                                <label className="login-label">Email</label>
                                <input className="login-input-field" type="email" placeholder="Enter your Email..." required/>
                                <label className="login-label">Mobile</label>
                                <PhoneInput className="mobile-number-validation"
                                        country={'in'} 
                                        // value={mobile}
                                        // onChange={(value) => handleMobileChange(value)}
                                        inputProps={{
                                            name: 'mobile',
                                            required: true,
                                        }}
                                />
                                {/* <input className="login-input-field" type="number" placeholder="Enter your mobile no..." required/> */}
                                <label className="login-label">Password</label>
                                <input className="login-input-field" type="password" placeholder="Enter your password..." required/>
                                <label className="login-label">Gender:&nbsp;</label>
                                <select className="login-input-field">
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                <br/>
                            </>
                        )}
                         
                        <br />
                        <button className="login-btn">Log In</button>
                        <br />
                        <br />
                        <div className="donot-have-ac">
                            <p>Don't have an Account? <a href="#">Register Now</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default LogIn;
