import React, { useEffect, useState } from "react";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Rating from '@mui/material/Rating';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import './Attractions.css';
import Footer from "../../Footer/Footer";
import APIPath from "../../../config";

const Attraction =()=>{
    const[attractions,setAttractions]=useState([]);
    const [originalAttractions, setOriginalAttractions] = useState([]);
    const [searchTerm,setSearchTerm]=useState('');
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
          fetch(`${APIPath}/api/v1/attractions`)
          .then((res)=>res.json())
          .then((data)=>{
            setAttractions(data.data)
            setOriginalAttractions(data.data);
            setLoading(false)
          })
          .catch((err)=>{
            alert(err)
          })
    },[]);

    const navigate = useNavigate();
    const handleCardId = (id,name) => {
      navigate(`/attraction/${id}`);
    }

    const handleSearch=()=>{
      const searchTermLower=searchTerm.toLowerCase();
      console.log("serach Key: ",searchTermLower);

      if (searchTermLower === "") {
        setAttractions(originalAttractions);
      }
      else {
        const filteredAttractions = originalAttractions.filter((attraction) =>
          attraction.title.toLowerCase().includes(searchTermLower)
        );
        setAttractions(filteredAttractions);
      }
    }

    return <>
       <div className="attraction-banner">
            <div className="attraction-banner-title">
              <h3>Attractions in Dubai</h3>
              <div id="search-container">
                <form className='video-form' onSubmit={(e)=>{e.preventDefault(); handleSearch()}}>
                    <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                    <button  className="search-button"><SearchIcon/> </button>
                </form>
              </div>
            </div>
          
       </div>
       <div className="attraction-container">
             <h3>Attractions</h3>
             {loading ? <div className="loader-container">
                <div className="loader"></div>
                </div> :
             <div className="attraction-card-parent">
                {attractions.map((val,id)=>{
                    return <>
                       <div key={id} className="attraction-card" onClick={() => handleCardId(val._id,val.categoryName)}>
                          <div className="attraction-card-image">
                            <img src={val.thumbnailImage}/>
                          </div>
                          <div className="attraction-card-details">
                                <h3>{val.title}</h3>
                                <div className="attraction-card-details-ratings">
                                    <p className="attraction-details">View details <ArrowOutwardIcon /></p>
                                    <div style={{display:"flex"}}>
                                        <p>Ratings: &nbsp;</p>
                                        <p><Rating defaultValue={4.5} precision={0.5} readOnly /></p>
                                    </div>
                                </div>
                          </div>
                       </div>
                    </>
                })}
             </div>
            }
       </div>
       <Footer/>
    </>
}
export default Attraction;
