import React, { useEffect, useState } from "react";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Rating from '@mui/material/Rating';
import SearchIcon from '@mui/icons-material/Search';
import './AttractionByCategory.css';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import APIPath from "../../../config";
import Footer from "../../Footer/Footer";

const AttractionByCategory=()=>{
    const {id,name}=useParams();
    // const decodedName = decodeURIComponent(name);
    // console.log(id,decodedName)
    const[attractionpackages,setAttractionPackages]=useState([])
    useEffect(()=>{
         fetch(`${APIPath}/api/v1/attractions?categoryId=${id}`)
         .then((res)=>res.json())
         .then((data)=>{
            // console.log(data.data)
            setAttractionPackages(data.data)
         }).catch((err)=>{
            alert(err)
         })
    },[]);

const CategoryName=attractionpackages[0]?.categoryName;

    const navigate = useNavigate();
    const handleCardId = (id) => {
      // console.log(id)
      navigate(`/attraction/${id}`);
    }

    return<>
    {/* -------------------------------------------------Top Banner */}
    <div className="attraction-banner">
            <div className="attraction-banner-title">
              <h3>Attractions in Dubai</h3>
              <div id="search-container">
                <form className='video-form'>
                    <input className="search-input" type="text" placeholder="Search..." />
                    <button  className="search-button"><SearchIcon/> </button>
                </form>
              </div>
            </div>
          
       </div>
    {/* -------------------------------------------------- */}
         <div className="attractionbycategory">
              <h2>{CategoryName}</h2>
              <br/>
              <div className="attraction-card-parent">
                {attractionpackages.length > 0 ? attractionpackages.map((val,id)=>{
                    return<>
                        <div key={id} className="attraction-card" onClick={() => handleCardId(val._id)}>
                          <div className="attraction-card-image">
                            <img src={val.thumbnailImage}/>
                          </div>
                          <div className="attraction-card-details" >
                                <h3>{val.title}</h3>
                                <div className="attraction-card-details-ratings">
                                    <p className="attraction-details">View details <ArrowOutwardIcon /></p>
                                    <div style={{display:"flex"}}>
                                        <p>Ratings: &nbsp;</p>
                                        <p><Rating defaultValue={4.5} precision={0.5} readOnly /></p>
                                    </div>
                                </div>
                          </div>
                       </div>
                    </>
                }): <h1>Packages are Not Available for Now</h1>}

              </div>
         </div>
         <Footer/>
    </>
}
export default AttractionByCategory;