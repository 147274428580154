import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import './Delicacies.css';
import APIPath from "../../../config";

const Delicacies=()=>{
    const [delicacies,setDelicacies]=useState([])
    const [clickedCardId, setClickCardId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
         fetch(`${APIPath}/api/v1/delicacies`)
         .then((res)=> res.json())
         .then((data)=>{
            setDelicacies(data.data)
            // console.log(data.data)
            setLoading(false)
         })
         .catch((err)=>{
            console.log(err)
         })
    },[])

    const navigate = useNavigate();
    const handleCardId = (id,name) => {
        setClickCardId(id);
        navigate(`/delicaciesdetails/${id}`);
    }
  const handlNext=()=>{
    const newDelicacies = [...delicacies];
    const lastCard = newDelicacies.pop();
    newDelicacies.unshift(lastCard);
    setDelicacies(newDelicacies);
  }

    return <>
    {loading ? <div className="loader-container">
                <div className="loader"></div>
                </div> :
      <div className="delicacies">
        <div className="delicacies-top-header">
           <h2>Delicacies for Indians</h2>
          <p>
            <button className="supersell-btn" onClick={handlNext}> <IoIosArrowBack /> </button>
            <button className="supersell-btn" onClick={handlNext}> <IoIosArrowForward /> </button>
         </p>
        </div>
        <div className="delicacies-card-parent">
            {delicacies.map((val,id)=>{
                return <>
                  <div key={id} className="delicacies-card" onClick={() => handleCardId(val._id)}>
                      <div className="delicacies-card-image">
                        <img src={val.thumbnailImage} />
                      </div>
                      <div className="delicacies-card-location-logo">
                            <div className="delicacies-card-location">
                                <p><IoLocationOutline/> {val.location}</p>
                            </div>
                            <br/>
                            <div className="delicacies-card-logo">
                              <img src={val.logoImage} />
                            </div>
                      </div>
                  </div>
                </>
            })}
        </div>
      </div>
    }
    </>
}
export default Delicacies;